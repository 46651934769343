import React from "react";
import googlePlayBadgeImage from "~/images/google-play-badge.png";
import appStoreImage from "~/images/app-store.png";
// import windowsImage from "~/images/windows-download.png";

function DownloadLinks() {
  return (
    <>
      <div
        id="download-links-container"
        className="row sqs-row"
        style={{ paddingTop: 30, paddingBottom: 30 }}
      >
        <div className="col sqs-col-1 span-1">
          <div
            className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
            data-block-type="21"
            id="block-7622f0ee7ecda3be6ff8"
          >
            <div className="sqs-block-content">&nbsp;</div>
          </div>
        </div>
        <div className="col sqs-col-11 span-11">
          <div className="row sqs-row">
            <div className="col sqs-col-10 span-10">
              <div
                className="sqs-block html-block sqs-block-html"
                data-block-type="2"
                id="block-2a5eb0f967472f566a64"
              >
                <div className="sqs-block-content">
                  <h3
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: "120%",
                    }}
                  >
                    ProblemScape is currently available for download on iPads,
                    Android tablets, Chromebooks, and PCs (macOS).
                    {/* Android tablets, Chromebooks, and PCs (both macOS and Windows). */}
                    Please note that ProblemScape is not compatible with phones.
                    And try the first chapter at no cost!
                  </h3>
                </div>
              </div>
            </div>
            <div className="col sqs-col-1 span-1">
              <div
                className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
                data-block-type="21"
                id="block-509be883060ca39ad748"
              >
                <div className="sqs-block-content">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row sqs-row" style={{ flexWrap: "wrap" }}>
        <div className="col sqs-col-6 span-6" style={{ textAlign: "center" }}>
          <a
            className="sqs-block-image-link"
            href="https://apps.apple.com/us/app/id1456312892"
            style={{ maxWidth: "100%" }}
          >
            <img
              className="thumb-image loaded"
              data-src={appStoreImage}
              data-image={appStoreImage}
              data-image-dimensions="500x148"
              data-image-focal-point="0.5,0.5"
              alt=""
              data-load="false"
              data-image-id="62dea2712ab9183c045634fc"
              data-type="image"
              data-image-resolution="500w"
              src={appStoreImage}
              style={{ maxWidth: "100%" }}
            />
          </a>
        </div>
        <div className="col sqs-col-6 span-6" style={{ textAlign: "center" }}>
          <a
            className="sqs-block-image-link"
            href="https://play.google.com/store/apps/details?id=com.roundedlearning.problemscapeValueOfXperts"
            style={{ maxWidth: "100%" }}
          >
            <img
              data-src={googlePlayBadgeImage}
              data-image={googlePlayBadgeImage}
              data-image-dimensions="400x118"
              data-image-focal-point="0.5,0.5"
              alt=""
              data-load="false"
              data-image-id="632331bbe7e20d6d4f4e7f3c"
              data-type="image"
              data-image-resolution="500w"
              src={googlePlayBadgeImage}
              style={{ maxWidth: "100%" }}
            />
          </a>
        </div>
        {/* <div
                        className="col sqs-col-4"
                        id="yui_3_17_2_1_1671150646035_226"
                      >
                        <div
                          className="sqs-block image-block sqs-block-image sqs-text-ready"
                          data-block-type="5"
                          id="block-c8a0f2850857fe8bc1b0"
                        >
                          <div
                            className="sqs-block-content"
                            id="yui_3_17_2_1_1671150646035_225"
                          >
                            <div
                              className="image-block-outer-wrapper
                                layout-caption-below
                                design-layout-inline
                                combination-animation-none
                                individual-animation-none
                                individual-text-animation-none
                                sqs-narrow-width"
                              data-test="image-block-inline-outer-wrapper"
                              id="yui_3_17_2_1_1671150646035_224"
                            >
                              <figure
                                className="sqs-block-image-figure intrinsic"
                                style={{ maxWidth: 400 }}
                                id="yui_3_17_2_1_1671150646035_223"
                              >
                                <a
                                  className="sqs-block-image-link"
                                  href="https://www.microsoft.com/store/apps/9NB5NZVQ07V4"
                                  id="yui_3_17_2_1_1671150646035_222"
                                >
                                  <div
                                    className="image-block-wrapper"
                                    data-animation-role="image"
                                    id="yui_3_17_2_1_1671150646035_221"
                                  >
                                    <div
                                      className="sqs-image-shape-container-element has-aspect-ratio"
                                      style={{
                                        position: "relative",
                                        paddingBottom: "31%",
                                        overflow: "hidden",
                                      }}
                                      id="yui_3_17_2_1_1671150646035_220"
                                    >
                                      <noscript>
                                        <img src={windowsImage} alt="" />
                                      </noscript>
                                      <img
                                        className="thumb-image loaded"
                                        data-src={windowsImage}
                                        data-image={windowsImage}
                                        data-image-dimensions="500x155"
                                        data-image-focal-point="0.5,0.5"
                                        alt=""
                                        data-load="false"
                                        data-image-id="62dea2722ab9183c04563502"
                                        data-type="image"
                                        style={{
                                          left: "-0.168792%",
                                          top: "0%",
                                          width: "100.338%",
                                          height: "100%",
                                          position: "absolute",
                                        }}
                                        data-image-resolution="500w"
                                        src={windowsImage}
                                      />
                                    </div>
                                  </div>
                                </a>
                              </figure>
                            </div>
                          </div>
                        </div>
                      </div> */}
      </div>
    </>
  );
}

export default DownloadLinks;
